#contact {
    text-align: center;
    max-width: 100%;
    min-height: 890px;
    padding-top: 80px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/main_bg3.jpg");
}
p.head {
    padding: 40px 0;
    font-size: 30px;
	font-weight: 100;
    letter-spacing: .5rem;
    text-transform: uppercase;
}
.row {
    display: flex;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.8em;
    font-size: 16px;
}
.column {
    flex: 50%;
}
.greeting {
    text-align: left;
    padding-right: 30px;
    padding-left: 20%;
    padding-right: 10%;
}
.greeting-head {
    font-size: 18px;
    font-weight: 900;
    padding: 30px 0;
}
.contact-info {
    padding: 30px 0;
}
.contact-form-container {
    text-align: left;
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
}
.form-group {
    margin-bottom: 1rem;
}
label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #FAC898;
}
input, textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 0.9rem;
}
textarea {
    resize: vertical;
}
.error-message {
    color: red;
    font-size: 0.875rem;
    display: none; /* Hidden by default */
}
.submit-button {
    background-color: #FAA0A0;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}
.submit-button:hover {
    background-color: #f88f8f;
}
.overlay {
    display: none;
    text-align: center;
    background-color: #ddd;
    width: 250px;
    height: 75px;
    padding : 20px;
    border-radius: 4px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: white; background: #816e6e; opacity: .8;
    z-index: 1000;
}
@media only screen and (max-width: 1500px) {
    .greeting {
        padding-left: 15%;
        padding-right: 10%;
    }
}

@media only screen and (max-width: 1130px) {
    .greeting {
        padding-left: 10%;
        padding-right: 25px;
    }
}
@media only screen and (max-width: 768px) {
    #contact {
        padding-top: 0;
        padding-bottom: 50px;
    }
    .row {
        display: block;
    }
    .greeting {
        padding-left: 25px;
        padding-right: 25px;
    }
    .greeting-head {
        padding: 10px 0 30px 0;
    }
}