#awards {
    text-align: center;
    max-width: 100%;
    min-height: 890px;
    padding-top: 80px;
    padding-bottom: 70px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/main_bg3.jpg");
}
.head {
    padding: 40px 0;
    font-size: 30px;
	font-weight: 100;
    letter-spacing: .5rem;
    text-transform: uppercase;
}
.sub-head {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 900;
    text-decoration: underline;
}
.sub-text {
    font-size: 10px;
    font-style: italic;
    padding-left: 20px;
}
.list {
    padding: 0 20%;
    text-align: left; 
}
.list table {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.8em;
    font-size: 16px;
    padding-bottom: 30px;
}
.list .col1 {
    text-align: left;
    width: 200px;
}

@media only screen and (max-width: 768px) {
    #awards {
        padding-top: 0;
    }
    .list .col1 {
        width: 100px;
    }
    .list {
        padding: 0 20px;
    }
}