#about {
    text-align: center;
    max-width: 100%;
    min-height: 890px;
    padding-top: 80px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/main_bg3.jpg");
}
p.head {
    padding: 40px 0;
    font-size: 30px;
	font-weight: 100;
    letter-spacing: .5rem;
    text-transform: uppercase;
}
.row {
    display: flex;
}
.column {
    flex: 50%;
}
.profile-img {
    text-align: right;
    padding-right: 30px;
    padding-left: 50px;
}
.profile-img img {
    text-align: right;
    width: 600px;
    height: auto;
}
.artist-statement {
    padding-left: 30px;
    padding-right: 18%;
    text-align: left;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    line-height: 1.8em;
    font-size: 16px;
}
.artist-statement p {
    padding-top: 50px;
}

@media only screen and (max-width: 1500px) {
    .artist-statement {
        padding-right: 50px;
    }
    .artist-statement p {
        padding-top: 25px;
    }
}

@media only screen and (max-width: 1130px) {
    .artist-statement p {
        padding-top: 0;
    }
}

@media only screen and (max-width: 768px) {
    #about {
        padding-top: 0;
        padding-bottom: 55px;
    }
    .row {
        display: block;
    }
    .profile-img {
        width: 100%;
        padding: 0;
        text-align: center;
    }
    .artist-statement {
        padding-left: 20px;
        padding-right: 20px;
    }
    .artist-statement p {
        padding-top: 25px;
    }
}