/* header {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    height: 85px;
}
.head-link {
    font-family: Snell Roundhand, cursive;
    font-size: 40px;
    font-weight: bold;
    width: 50%;
    float: left;
    padding-left: 20px;
}
.head-link a {
    color: #fff;
    text-decoration: none;
}
.head-link img {
    width: 200px;
    height: auto;
}
.nav {
    float: right;
} */


li {
    list-style: none;
}

a {
    text-decoration: none;
}

.header {
    background-color: #000;
    color: #fff;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
}
.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #ffffff;
}

.nav-menu {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    display: flex; 
    justify-content: space-between; 
    align-items: center;
}

.nav-item {
    margin-left: 4rem;
}

.nav-link {
    font-size: 1.0rem;
    font-weight: 400;
    color: #c5c5c5;
}

.nav-link:hover {
    color: #f8f8f8;
    font-weight: 900;
}

.nav-logo {
    font-family: Snell Roundhand, cursive;
    font-size: 40px;
    font-weight: bold;
    width: 50%;
    float: left;
    padding-left: 20px;
}

.nav-logo a {
    color: #fff;
    text-decoration: none;
}

.nav-logo img {
    width: 200px;
    height: auto;
}

@media only screen and (max-width: 768px) {
    .nav-menu {
    position: fixed;
    left: -100%;
    top: 5rem;
    flex-direction: column;
    background-color: #494848;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    transition: 0.3s;
    box-shadow:
    0 10px 27px rgba(0, 0, 0, 0.05);
    }
    
    .nav-menu.active {
        left: 0;
    }
    
    .nav-item {
        margin: 1.0rem 0;
    }
    
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }
    
    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }
    
    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }
}