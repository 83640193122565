@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap');


* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}
:root {
  --header-height: 3.5rem;
  --first-color: hsl(28, 88%, 62%);
  --title-color: hsl(0, 0%, 95%);
  --text-color: hsl(0, 0%, 75%);
  --body-color: hsl(0, 0%, 6%);
  --body-font: "Montserrat", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.25rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
 }

html,
body {
  height: 100%;
  background-color: #000;
  color: #fff;
  font-family: var(--body-font);
  /* padding: 15px 0; */
}

body {
  margin: 0;
  font-family: serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul {
  list-style: none;
 }
 
 a {
  text-decoration: none;
 }