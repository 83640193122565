main {
    text-align: center;
    background-color: #000;
    color: #fff;
    max-width: 100%;
    min-height: 890px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../assets/images/main_bg3.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#home-content {
    padding-top: 290px;
}
#home-content .small {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 20px;
}
#home-content .big {
    font-size: 100px;
    font-weight: 100;
    letter-spacing: .9rem;
    text-transform: uppercase;
}