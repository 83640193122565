@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-share.css');

* {
  box-sizing: border-box;
}

#arts {
    text-align: center;
    max-width: 100%;
    min-height: 890px;
	padding-top: 80px;
    padding-bottom: 80px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../../assets/images/main_bg3.jpg");
}

p.head {
	padding: 40px 0;
    font-size: 30px;
	font-weight: 100;
    letter-spacing: .5rem;
    text-transform: uppercase;
}

/* Reset CSS */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	display: inline-block;
}

.grid-wrapper {
  padding-top: 30px;
  margin: auto;
  width: 70%;
}

/* Main CSS */
.grid-wrapper > .lg-react-element > a {
	display: flex;
	justify-content: center;
	align-items: center;
}
.grid-wrapper > .lg-react-element > a > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}

.grid-wrapper > .lg-react-element {
  text-align: center;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	grid-auto-rows: 200px;
	grid-auto-flow: dense;
}
.grid-wrapper .wide {
	grid-column: span 2;
}
.grid-wrapper .tall {
	grid-row: span 2;
}
.grid-wrapper .big {
	grid-column: span 2;
	grid-row: span 2;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
	height: 97%;
}

@media only screen and (max-width: 768px) {
	#arts {
		padding-top: 0;
		padding-bottom: 80px;
	}
	.grid-wrapper {
		width: 100%;
	}
}