footer {
    width: 100%;
    bottom: 0px;
    background-color: #000;
    color: #fff;
    position: fixed;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 14px;
}